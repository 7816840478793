import * as actionTypes from './actionTypes';

export const searchClientRequest = (clientId) => ({
  type: actionTypes.SEARCH_CLIENT_REQUEST,
  clientId,
});

export const saveClientRequest = (data) => ({
  type: actionTypes.SAVE_CLIENT_REQUEST,
  data,
});
