import * as actionTypes from './actionTypes';

export * as actionTypes from './actionTypes';
export * as uiActions from './ui';
export * as authActions from './auth';
export * as clientsActions from './clients';

export const setState = (
  keyOrRootValues,
  maybeValues,
) => ({
  type: actionTypes.SET_STATE,
  payload: {
    keyOrRootValues,
    maybeValues,
  },
});
