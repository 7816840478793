export const getUiState = keyOrKeys => (
  ({ ui }) => (
    Array.isArray(keyOrKeys)
      ? (
        keyOrKeys.reduce(
          (acc, key) => ({
            ...acc,
            [key]: ui[key],
          }),
          {},
        )
      )
      : ui[keyOrKeys]
  )
);
