import React from 'react';
import {
  shallowEqual,
  useSelector,
  useDispatch,
} from 'react-redux';
import {
  useHistory,
} from "react-router-dom";

import {
  Form,
  Input,
  Button,
  Checkbox,
} from 'antd';

const mapState = state => ({
  isTest: true,
});

const MainPage = () => {
  const { isTest } = useSelector(mapState, shallowEqual);
  const dispatch = useDispatch();
  const history = useHistory();
  console.log('isMainPage');
  return (
    <div>
      <Input.Search
        placeholder="input organization id or account id"
        style={{ width: 600, margin: '15px 10px 0px 10px' }}
        size="large"
        enterButton="Search"
        onSearch={(clientId) => {
          history.push(`/client/${clientId}`);
        }}
      />
    </div>
  );
};

export default MainPage;
