import * as eff from 'redux-saga/effects';
import createActionCreators from 'redux-resource-action-creators';

import {
  uiActions,
  actionTypes,
} from 'actions';
import api from 'api';
import {
  jsonApiNormalizr,
} from 'utils';

function* searchClient({ clientId }) {
  const clientActions = createActionCreators('read', {
    resourceType: 'clients',
    requestKey: 'searchClient',
    list: 'clients',
  });
  try {
    yield eff.put(clientActions.pending());
    const response = yield eff.call(
      api.searchClient,
      {
        params: {
          clientId,
        },
      },
    );
    const normalizedData = jsonApiNormalizr(response);
    yield eff.put(clientActions.succeeded(normalizedData));
  } catch (err) {
    console.log(err);
    yield eff.put(clientActions.failed());
  }
}

function* saveClient({ data }) {
  const clientActions = createActionCreators('update', {
    resourceType: 'clients',
    requestKey: 'updateClient',
    list: 'clients',
  });
  try {
    yield eff.put(clientActions.pending());
    const response = yield eff.call(
      api.saveClient,
      {
        body: data,
      },
    );
    yield eff.put(clientActions.succeeded({
      resources: response.data,
    }));
  } catch (err) {
    console.log(err);
    yield eff.put(clientActions.failed());
  }
}

export function* takeSearchClientRequest() {
  yield eff.takeEvery(
    actionTypes.SEARCH_CLIENT_REQUEST,
    searchClient,
  );
}

export function* takeSaveClientRequest() {
  yield eff.takeEvery(
    actionTypes.SAVE_CLIENT_REQUEST,
    saveClient,
  );
}
