import {
  actionTypes,
} from 'actions';

import keyValueReducer from './keyValueReducer';

const initialState = {
  initializeInProcess: true,
  authRequestInProcess: false,
  isAuthorized: false,
  userData: {
    username: null,
    role: null,
  },
};

export default function ui(
  state = initialState,
  action,
) {
  switch (action.type) {
    case actionTypes.SET_UI_STATE: {
      return keyValueReducer(state, action);
    }
    case actionTypes.CLEAR_ALL_REDUCERS:
      return initialState;
    default:
      return state;
  }
}
