import React from 'react';
import PropTypes from 'prop-types';
import {
  shallowEqual,
  useSelector,
} from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import {
  uiSelectors,
} from 'selectors';

import {
  Spin,
} from 'antd';

import ErrorBoundary from 'components/ErrorBoundary';
import Page from 'containers/Page';
import AuthPage from 'containers/AuthPage';
import MainPage from 'containers/MainPage';
import ClientPage from 'containers/ClientPage';

import * as S from './styled';

const mapState = state => ({
  isAuthorized: uiSelectors.getUiState('isAuthorized')(state),
  initializeInProcess: uiSelectors.getUiState('initializeInProcess')(state),
});

function PrivateRoute({ children, ...rest }) {
  const { isAuthorized } = useSelector(mapState, shallowEqual);
  return (
    <Route
      {...rest}
      render={({ location }) => (
        isAuthorized ? (
          <Page>
            {children}
          </Page>
        ) : (
          <Redirect
            to={{
              pathname: '/auth',
              state: { from: location },
            }}
          />
        )
      )}
    />
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

const AppContainer = () => {
  const { initializeInProcess } = useSelector(mapState, shallowEqual);

  if (initializeInProcess) {
    return (
      <S.Center>
        <Spin size="large" />
      </S.Center>
    );
  }

  return (
    <ErrorBoundary>
      <Router>
        <Switch>
          <Route path="/auth">
            <AuthPage />
          </Route>
          <PrivateRoute path="/client/:clientId">
            <ClientPage />
          </PrivateRoute>
          <PrivateRoute path="/">
            <MainPage />
          </PrivateRoute>
        </Switch>
      </Router>
    </ErrorBoundary>
  );
};

export default AppContainer;
