import React from 'react';
import PropTypes from 'prop-types';

import {
  Layout,
  Menu,
  Input,
} from 'antd';
import {
  Link,
  useHistory,
} from 'react-router-dom';

const { Header, Content, Footer } = Layout;

const Page = ({ children }) => {
  const history = useHistory();

  return (
    <Layout>
      <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
        <div style={{ display: 'flex' }}>
          <Input.Search
            style={{ width: 300, margin: '15px 10px 0px 10px' }}
            onSearch={(clientId) => {
              history.push(`/client/${clientId}`);
            }}
          />
          <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
            <Menu.Item key="1">
              <Link to="/">Main</Link>
            </Menu.Item>
          </Menu>
        </div>
      </Header>
      <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
        <div className="site-layout-background" style={{ padding: 24, minHeight: 380 }}>
          {children}
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        Chronos admin panel
      </Footer>
    </Layout>
  );
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Page;
