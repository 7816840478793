import * as actionTypes from './actionTypes';

export const setUiState = (
  keyOrRootValues,
  maybeValues,
) => ({
  type: actionTypes.SET_UI_STATE,
  payload: {
    keyOrRootValues,
    maybeValues,
  },
});
