import {
  combineReducers,
} from 'redux';
import {
  resourceReducer,
} from 'redux-resource';

import includedResources from './plugins/includedResources';
import dependenciesList from './plugins/dependenciesList';

import ui from './ui';

const rootReducer = combineReducers({
  ui,
  clients: resourceReducer('clients', {
    plugins: [
      includedResources,
      dependenciesList,
    ],
  }),
  orders: resourceReducer('orders', {
    plugins: [
      includedResources,
      dependenciesList,
    ],
  }),
});

export default rootReducer;
