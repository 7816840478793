import React, {
  useEffect,
} from 'react';
import {
  shallowEqual,
  useSelector,
  useDispatch,
} from 'react-redux';
import {
  useHistory,
  useLocation,
} from 'react-router-dom';

import {
  Form,
  Input,
  Button,
  Checkbox,
} from 'antd';
import {
  UserOutlined,
  LockOutlined,
} from '@ant-design/icons';

import {
  authActions,
} from 'actions';
import {
  uiSelectors,
} from 'selectors';
import api from 'api';

import * as S from './styled';

const mapState = state => ({
  isAuthorized: uiSelectors.getUiState('isAuthorized')(state),
});

const AuthPage = () => {
  const history = useHistory();
  const location = useLocation();
  const { isAuthorized } = useSelector(mapState, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthorized) {
      const { from } = location.state || { from: { pathname: '/' } };
      history.replace(from);
    }
  }, [isAuthorized]);

  return (
    <S.Auth>
      <Form
        size="large"
        name="auth"
        initialValues={{
          remember: true,
        }}
        onFinish={async (values) => {
          try {
            const { jwtToken } = await api.auth({
              body: values,
            });
            window.localStorage.setItem('JWT', jwtToken);
            dispatch(authActions.initializeAuthorizedRequest());
          } catch (err) {
            console.log(err);
          }
        }}
        onFinishFailed={(errorInfo) => {
          console.log('Failed:', errorInfo);
        }}
      >
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: 'Please input your username!',
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Username"
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>

        <Form.Item name="remember" valuePropName="checked">
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
            Log in
          </Button>
        </Form.Item>
      </Form>
    </S.Auth>
  );
};

export default AuthPage;
