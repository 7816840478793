import {
  all,
  fork,
} from 'redux-saga/effects';

import initialize from './initialize';
import {
  takeSearchClientRequest,
  takeSaveClientRequest,
} from './clients';

export default function* rootSaga() {
  yield all([
    fork(initialize),
    fork(takeSearchClientRequest),
    fork(takeSaveClientRequest),
  ]);
}
