import {
  createSelector,
} from 'reselect';
import * as R from 'ramda';

export const getResourceIds = (
  resourceType,
  listName,
) => state => state[resourceType].lists[listName];

export const getResourceMap = resourceType => state => state[resourceType].resources;

export const getResourceMeta = (
  resourceType,
  metaKey,
) => state => state[resourceType].meta[metaKey];

const resourceSelectors = {};

export const getResourceMappedList = (
  resourceType,
  listName,
) => {
  if (resourceSelectors[`${resourceType}${listName}`]) {
    return resourceSelectors[`${resourceType}${listName}`];
  }
  resourceSelectors[`${resourceType}${listName}`] = (
    createSelector(
      [
        getResourceIds(resourceType, listName),
        getResourceMap(resourceType),
      ],
      (ids = [], map) => ids.map(id => map[id]),
    )
  );
  return resourceSelectors[`${resourceType}${listName}`];
};

export const getResourceItemById = (
  resourceType,
  id,
) => state => state[resourceType].resources[id] || null;

export function getNestedResourceItem(
  resourceType,
  id,
  state,
  computedAttributesCreator,
  wholeState,
) {
  if (!(state[resourceType] && id)) {
    return null;
  }
  const resourcesState = state[resourceType];
  const resource = (wholeState ? resourcesState.resources : resourcesState)[id];
  if (!resource) {
    return null;
  }
  const resourceWithRl = {
    ...resource,
    rl: R.keys(resource.relationships).reduce(
      (acc, relationName) => {
        const relT = resource.relationships[relationName];
        acc[relationName] = Array.isArray(relT.data) ?
          relT.data.map(rId => getNestedResourceItem(
            relT.type,
            rId,
            state,
            computedAttributesCreator,
            wholeState,
          )) :
          getNestedResourceItem(
            relT.type,
            relT.data,
            state,
            computedAttributesCreator,
            wholeState,
          );
        return acc;
      },
      {},
    ),
  };
  if (computedAttributesCreator) {
    resourceWithRl.ca = computedAttributesCreator(resourceType, resourceWithRl, state);
  }
  return resourceWithRl;
}

/* Selector which return list of resources with all relationships */
export function getResourceNestedMappedList(
  resourceType,
  list,
  state,
  reverse,
  // computedAttributesCreator allow extend resource object with custom attributes
  computedAttributesCreator,
  customReducersList,
) {
  const listName = typeof list === 'string' ? list : list.listName;
  const selectorNamePrefix = typeof list === 'string' ? '' : (list.selectorNamePrefix || '');
  const relDependencies =
    (state[resourceType].lists[`${list.relationList || list}Dependencies`] || []);
  const selectorName = `${resourceType}${listName}${relDependencies.join()}${selectorNamePrefix}`;
  if (resourceSelectors[selectorName]) {
    return resourceSelectors[selectorName];
  }

  resourceSelectors[selectorName] =
    // $FlowFixMe it can't be fixed because of reselect definition
    createSelector(
      [
        list.idsSelector || getResourceIds(resourceType, listName),
        ...relDependencies.map(
          rt =>
            getResourceMap(rt),
        ).concat(
          customReducersList.map(rd => s => s[rd]),
        ),
      ],
      (
        ids = [],
        ...resourcesMap
      ) => (reverse ? R.reverse(ids) : ids).map(
        id =>
          getNestedResourceItem(
            resourceType,
            id,
            // $FlowFixMe
            relDependencies.concat(customReducersList).reduce(
              (acc, rT, index) => {
                acc[rT] = resourcesMap[index];
                return acc;
              },
              {},
            ),
            computedAttributesCreator,
          ),
      ),
    );
  return resourceSelectors[selectorName];
}
