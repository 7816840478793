import {
  createStore,
  applyMiddleware,
  compose,
} from 'redux';
import {
  createLogger,
} from 'redux-logger';
import createSagaMiddleware, {
  END,
} from 'redux-saga';

import config from 'config';
import rootReducer from '../reducers';

/* eslint-disable no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const logger = createLogger();
/* eslint-enable no-underscore-dangle */
const sagaMiddleware = createSagaMiddleware();
const middleware = [
  sagaMiddleware,
  config.reduxLogger && logger,
].filter(Boolean);

function configureStore(initialState) {
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(...middleware),
    ),
  );

  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);
  return store;
}

export default configureStore;
