// keyValue
export const SET_STATE = 'SET_STATE';
// ui
export const SET_UI_STATE = 'ui/SET_UI_STATE';
// auth
export const INITIALIZE_AUTHORIZED_REQUEST = 'auth/INITIALIZE_AUTHORIZED_REQUEST';
// clients
export const SEARCH_CLIENT_REQUEST = 'clients/SEARCH_CLIENT_REQUEST';
export const SAVE_CLIENT_REQUEST = 'clients/SAVE_CLIENT_REQUEST';
// other
export const CLEAR_ALL_REDUCERS = 'CLEAR_ALL_REDUCERS';
