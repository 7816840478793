import * as eff from 'redux-saga/effects';

import {
  uiActions,
  actionTypes,
} from 'actions';
import api from 'api';

function* initializeAuthorized() {
  try {
    const jwt = window.localStorage.getItem('JWT');
    if (!jwt) {
      yield eff.put(uiActions.setUiState({
        isAuthorized: false,
      }));
    }
    api.setJWT(jwt);
    const userData = yield eff.call(api.me);
    yield eff.put(uiActions.setUiState({
      isAuthorized: true,
      userData,
    }));
  } catch (err) {
    console.log(err);
    yield eff.put(uiActions.setUiState({
      isAuthorized: false,
    }));
  } finally {
    yield eff.put(uiActions.setUiState({
      initializeInProcess: false,
    }));
  }
}

function* takeInitializeRequest() {
  yield eff.takeEvery(actionTypes.INITIALIZE_AUTHORIZED_REQUEST, initializeAuthorized);
}

export default function* initialize() {
  yield eff.put(uiActions.setUiState({
    initializeInProcess: true,
  }));
  yield eff.fork(takeInitializeRequest);
  try {
    yield eff.call(initializeAuthorized);
  } catch (err) {
    console.log(err);
  } finally {
    yield eff.put(uiActions.setUiState({
      initializeInProcess: false,
    }));
  }
}
