import {
  createSelector,
} from 'reselect';
import {
  DateTime,
} from 'luxon';
import moment from 'moment';

import {
  getResourceMap,
  getNestedResourceItem,
} from './resources';

const getPeriodMonths = (userPrice) => {
  if (userPrice === 5) {
    return 3;
  }
  if (userPrice === 4) {
    return 6;
  }
  return 12;
};

export const getClientById = createSelector(
  [
    (_, clientId) => clientId,
    getResourceMap('clients'),
    getResourceMap('orders'),
  ],
  (
    clientId,
    clients,
    orders,
  ) => {
    const id = Object.keys(clients).find(
      cId => (
        clients[cId].attributes.accountId === clientId
        || clients[cId].attributes.organizationId === clientId
      ),
    );
    const client = (
      id ? (
        getNestedResourceItem(
          'clients',
          id,
          {
            clients,
            orders,
          },
          (resourceType, item) => {
            if (resourceType === 'orders') {
              const date = DateTime.fromISO(item.attributes.updatedAt).toFormat('MM/dd/yyyy');
              const {data} = item.attributes;
              console.log(item)
              console.log(data)
              const total = Number(data.total, 10);
              const periodMonths = getPeriodMonths(item.attributes.data.userPrice);
              const qty = item.attributes.data.qty || (total / (data.userPrice * periodMonths));
              const discount = total !== data.totalWithDiscount ? total - data.totalWithDiscount : null;
              const description = [
                `$${data.userPrice}(per user) x ${qty}(users)`,
                ...(
                  discount ? [` - $${discount}(Discount)`] : []
                ),
                `for ${periodMonths} months`,
              ].join('');
              return {
                ...item,
                date,
                discount,
                qty,
                periodMonths,
                description,
              };
            }
            const startedAt = DateTime.fromISO(item.attributes.startedAt);
            const finishedAt = DateTime.fromISO(item.attributes.finishedAt);
            const { days } = finishedAt.diff(startedAt, 'days').toObject();
            const { days: remainingDays } = finishedAt.diffNow('days').toObject();
            return {
              finishedAtMoment: moment(item.attributes.finishedAt),
              startedAtShortString: startedAt.toFormat('dd LLL yyyy t'),
              finishedAtShortString: finishedAt.toFormat('dd LLL yyyy t'),
              activeAccountsLength: (item.attributes.activeAccounts || []).length,
              period: Math.round(days),
              remainingDays: Math.round(remainingDays),
            };
          },
        )
      ) : null
    );
    if (client) {
      client.rl.orders = (client.rl.orders || []).reverse();
    }
    return client;
  },
);
