import React, {
  useEffect,
  useReducer,
} from 'react';
import {
  shallowEqual,
  useSelector,
  useDispatch,
} from 'react-redux';
import {
  useParams,
} from 'react-router-dom';

import {
  Button,
  Descriptions,
  Table,
  DatePicker,
  InputNumber,
  Typography,
} from 'antd';
import {
  EditOutlined,
} from '@ant-design/icons';

import config from 'config';
import {
  clientsActions,
  setState,
} from 'actions';
import {
  clientsSelectors,
} from 'selectors';
import keyValueReducer from 'reducers/keyValueReducer';

const columns = [
  {
    title: 'Date',
    dataIndex: ['ca', 'date'],
    key: 'date',
  },
  {
    title: 'Description',
    dataIndex: ['ca', 'description'],
    key: 'payer',
  },
  {
    title: 'Period',
    dataIndex: ['ca', 'periodMonths'],
    key: 'periodMonths',
    render: (period) => (
      <div>
        {period} months
      </div>
    ),
  },
  {
    title: 'Qty',
    dataIndex: ['ca', 'qty'],
    key: 'qty',
  },
  {
    title: 'Discount',
    dataIndex: ['ca', 'discount'],
    key: 'price',
    render: (price) => (
      <div>
        {price} USD
      </div>
    ),
  },
  {
    title: 'Billed',
    dataIndex: ['attributes', 'data', 'totalWithDiscount'],
    key: 'price',
    render: (price, order) => {
      return (
        <div>
          <a href={`${config.apiUrl}/trello/invoice/${order.id}/${order.attributes.type || 'f'}`}>Invoice</a>
          <div>
            {price} USD
          </div>
        </div>
      );
    },
  },
];

const initialState = {
  isEditMode: false,
  qty: 0,
  finishedAt: null,
};

const mapState = clientId => state => ({
  client: clientsSelectors.getClientById(state, clientId),
});

const ClientPage = () => {
  const dispatch = useDispatch();
  const { clientId } = useParams();
  const { client } = useSelector(mapState(clientId), shallowEqual);
  const [state, internalDispatch] = useReducer(
    keyValueReducer,
    initialState,
  );

  useEffect(() => {
    dispatch(clientsActions.searchClientRequest(clientId));
  }, [clientId]);

  useEffect(() => {
    internalDispatch(setState({
      qty: client?.attributes?.qty,
      finishedAt: client?.cat?.finishedAtMoment,
    }));
  }, [client]);

  if (client) {
    return (
      <div>
        <Descriptions
          title={(
            <div style={{ display: 'flex' }}>
              <div>
                Client info
              </div>
              <div>
                {state.isEditMode ? (
                  <div>
                    <Button
                      style={{ marginLeft: '10px' }}
                      type="primary"
                      key="save"
                      onClick={() => {
                        if (
                          state.qty !== client.attributes.qty
                          || state.finishedAt !== client.ca.finishedAt
                        ) {
                          dispatch(clientsActions.saveClientRequest({
                            qty: state.qty,
                            finishedAt: state.finishedAt,
                            id: client.id,
                          }));
                        }
                        internalDispatch(setState({
                          isEditMode: false,
                        }));
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      style={{ marginLeft: '5px' }}
                      key="cancel"
                      onClick={() => {
                        internalDispatch(setState({
                          isEditMode: false,
                        }));
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                ) : (
                  <EditOutlined
                    style={{ marginLeft: '10px' }}
                    onClick={() => {
                      internalDispatch(setState({
                        isEditMode: true,
                      }));
                    }}
                  />
                )}
              </div>
            </div>
          )}
        >
          <Descriptions.Item label="Id">
            {client.attributes.accountId || client.attributes.organizationId}
          </Descriptions.Item>
          <Descriptions.Item label="Type">
            {client.attributes.accountId ? 'Account' : 'Organization'}
          </Descriptions.Item>
          <Descriptions.Item label="License type">
            {client.attributes.qty === 0 ? 'Trial' : 'Commercial'}
          </Descriptions.Item>
          <Descriptions.Item label="License qty">
            {state.isEditMode ? (
              <InputNumber
                min={0}
                defaultValue={client.attributes.qty}
                onChange={(qty) => {
                  internalDispatch(setState({
                    qty,
                  }));
                }}
              />
            ) : (
              client.attributes.qty
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Period">
            {client.ca.period}
          </Descriptions.Item>
          <Descriptions.Item label="Remaining days">
            {client.ca.remainingDays}
          </Descriptions.Item>
          <Descriptions.Item label="Active accounts">
            {client.ca.activeAccountsLength}
          </Descriptions.Item>
          <Descriptions.Item label="License start">
            {client.ca.startedAtShortString}
          </Descriptions.Item>
          <Descriptions.Item label="License end">
            {state.isEditMode ? (
              <DatePicker
                defaultValue={client.ca.finishedAtMoment}
                onChange={(finishedAt) => {
                  internalDispatch(setState({
                    finishedAt,
                  }));
                }}
              />
            ) : (
              client.ca.finishedAtShortString
            )}
          </Descriptions.Item>
        </Descriptions>
        <Table
          rowClassName={(record) => {
            /*
            if (record.attributes.capture.status !== 'COMPLETED') {
              return 'uncompleted-transaction';
            }
            */
            return null;
          }}
          dataSource={client.rl.orders}
          columns={columns}
          rowKey="id"
        />
        {(client.attributes.notes || '').split('\n').map((note, i) => (
          <Typography.Paragraph key={i}>
            {note}
          </Typography.Paragraph>
        ))}
      </div>
    );
  }

  return (
    <div><h1>Client Page</h1></div>
  );
};

export default ClientPage;
