import axios from 'axios';

import chronosApiFactory from './chronosApi';

axios.interceptors.response.use(r => r.data);
const req = params => axios(params);

const chronosApi = chronosApiFactory({
  makeRequest: req,
});

export default chronosApi;
